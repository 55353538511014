import CityApi from '../../api/cityApi'
import {City, AddressPostal} from '../../types/api/city'
import useCustomQuery from './useCustomQuery'
import { OptionsTypeReactQuery } from '../../types/api'

export enum ServerStateKeysEnum {
  Cities = 'cities',
  CityCode = 'city code',
}
export const useGetCities = (
  name: string,
  options?:
    | OptionsTypeReactQuery<City[]>
) =>
  useCustomQuery<City[], Error>(
    [ServerStateKeysEnum.Cities, name],
    (header) => CityApi.findAll(name, header),
    options,
  )


  export const useGetCityByPostal = 
(
    ville_crm_id: string, 
    code: string,
   options?: OptionsTypeReactQuery<AddressPostal[]>
) => {

  const codeformatted : string = code.replace(/[^a-zA-Z0-9]/g, '');
  return useCustomQuery<AddressPostal[], Error>(
    [ServerStateKeysEnum.CityCode, codeformatted],
    (header) => CityApi.findCodePostalByCityID(ville_crm_id,codeformatted,header),
    options,
  )
}
