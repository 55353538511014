import { useState, useContext } from 'react'
import CustomTabs from '../../components/common/CustomTabs'
import PersonalInformationsForm from '../../components/forms/personalInformationsForm'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import UpdatePasswordForm from '../../components/forms/updatePasswordForm'
import { useGetUser, useUpdateUser, useUpdateUserPassword, useUpdateSession, useGetSession} from '../../hooks/api/users'
import { User } from '../../types/api/user'
import { t, use } from 'i18next'
import { Typography, SxProps, Theme } from '@mui/material'
import useSelectedItemSideBar from '../../hooks/useSelectedItemSideBar'
import { ActiveEnterpriseContext } from '../../context/activeEnterpriseContext'
import { PhoneType } from '../../types'
import {
  validationRuleEmail,
  validationRuleFirstNameField,
  validationRuleLastNameField,
  validationRuleTitleField,
  validationRulePhoneComponent,
  validationRuleSimpleEmail,
} from '../../validations'
import { extractMainPhoneAndOthers, isMember } from '../../utils'
import useBlocker from '../../hooks/useBlocker'
import TabLoader from '../../components/common/tabLoader'
import Title from '../../components/common/title'
import CustomModal from '../../components/common/modals/customModal'
import BodyModalWithActions from '../../components/common/modals/bodyModalWithActions'
import CustomButton from '../../components/common/buttons/customButton'



const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  recovery_email: '',
  mainPhone: { phone_number: '', phone_number_post: '', type: '' as PhoneType },
  otherPhones: [
    { phone_number: '', phone_number_post: '', type: '' as PhoneType },
    { phone_number: '', phone_number_post: '', type: '' as PhoneType },
  ],
  address: {
    city: '',
    city_id: '',
    postal_code: '',
    civic_number: '',
    street: '',
    office: '',
    country: '',
    province: '',
  },
}
const getInitialValues = (user: User, isMember: boolean) => {
  const { contact, old_email, status, recovery_email, title, ...rest } = user
  return {
    ...rest,
    ...extractMainPhoneAndOthers(contact),
    ...(isMember ? { recovery_email, title } : {}),
  }
}

const PersonnalInformations = () => {
  useSelectedItemSideBar('myProfile.personalInformations')
  const { data: user, refetch, isLoading, isFetching: isFetchingUser } = useGetUser()
  
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { mutate: updateSession, error: updateError } = useUpdateSession()
  const [openPopup, setOpenPopup] = useState(false);
  const handleClose = () => {
    setOpenPopup(false)
  }
  const handleLogout = async () => {
    await updateSession(null) 
    localStorage.clear()
    window.location.href = `${process.env.REACT_APP_ACQ_SITE as string}/logout`
  }
  const formikPersonalInformations = useFormik({
    initialValues: {
      ...(user ? getInitialValues(user, isMember(activeEnterprise)) : initialValues),
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      first_name: validationRuleFirstNameField,
      last_name: validationRuleLastNameField,
      email: validationRuleEmail,
      ...(isMember(activeEnterprise)
        ? { recovery_email: 
          validationRuleSimpleEmail
        .test(
          'not-equal',
          'L\'adresse courriel de récupération doit être différente de l\'adresse courriel principale',
          function (value) {
            const { email } = this.parent; 
            return value !== email;
          }), 
          title: validationRuleTitleField }
        : {}),
      mainPhone: validationRulePhoneComponent,
      address: Yup.object().shape({
        street: Yup.string().required('Veuillez inscrire le nom de la rue'),
        office: Yup.string(),
        country: Yup.string().required('Obligatoire'),
        civic_number: Yup.string().required('Veuillez inscrire un numéro de rue'),
        city: Yup.string().when('province', {
          is: (value: string) => value !== 'QC',
          then: Yup.string().required('Veuillez saisir une ville'),
          otherwise: Yup.string().nullable(),
        }),
        city_id: Yup.string().when('province', {
          is: 'QC',
          then: Yup.string().required('Veuillez saisir une ville'),
          otherwise: Yup.string().nullable(),
        }),
        postal_code: Yup.string()
        .when([ 'province','city_id'], {
          is: (a: string, b: string) =>  a === 'QC' &&( b=== undefined || b === null || b===''),
          then: Yup.string().required('Veuillez saisir une ville'),
          otherwise: 
          Yup.string()
          .when('province', {
            is: (value: string) => value !== undefined || value !== null || value!=='',
            then: Yup.string().required('Le code postal ne correspond pas à la ville, veuillez vérifier'),
            otherwise: Yup.string()
              .required('Veuillez saisir un code postal')
              .matches(/^[a-z]\d[a-z][ -]?\d[a-z]\d$/i, 'Format non validé'),
          }),

        }),

     
        
        /* postal_code: Yup.string()
          .required('Veuillez saisir un code postal')
          .matches(/^[a-z]\d[a-z][ -]?\d[a-z]\d$/i, 'Format non validé'),*/
          
        province: Yup.string().required('Obligatoire'),
      }),
    }),
    onSubmit: async (values) => {
      const {
        mainPhone,
        otherPhones,
        address: { city, city_id, ...restAddress },
        ...rest
      } = values
      const address = city_id ? { city_id, ...restAddress } : { city, ...restAddress }
      const old_email = values.email !== user?.email ? user?.email : null
      const data = {
        contact: [mainPhone, ...otherPhones.filter((phone) => phone.phone_number)],
        ...rest,
        address,
        old_email,
      }
      await mutate(data, {
        onSuccess: async () => {
          await refetch()
          setIsEditable(false)
        },
      })
    },
  })
  const { mutate } = useUpdateUser(formikPersonalInformations)
  const formikUpdatePassword = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{10,}$/,
          `Mot de passe invalide. Votre mot de passe doit comporter au moins 10 caractères et 
          être composé d'au moins un chiffre, une majuscule, une minuscule et un caractère spécial.`,
        )
        .required('Obligatoire'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Les mots de passe doivent correspondre')
        .required('Obligatoire'),
    }),
    onSubmit: async (values, formikBag) => {
      const { newPassword: password, confirmPassword: password_confirmation } = values

      const data = {
        password,
        password_confirmation,
      }
      await mutateUpdateUserPassword(data, {
        onSuccess: async () => {
          formikBag.resetForm()
          setOpenPopup(true)
          // handleLogout()
        },
      })
    },
  })
  const { mutate: mutateUpdateUserPassword, isLoading: isLoadingUpdatePassword } =
    useUpdateUserPassword(formikUpdatePassword)
  useBlocker(formikPersonalInformations.dirty || formikUpdatePassword.dirty)
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  })

  const handleShowPassword = (key: 'newPassword' | 'confirmPassword') =>
    setShowPassword((showPassword) => ({ ...showPassword, [key]: !showPassword[key] }))
  const [isEditable, setIsEditable] = useState(false)
  return (
    <>
      <Title>{t('title')}</Title>
      <CustomTabs
        list={[
          {
            label: 'Coordonnées',
            element: (
              <TabLoader isLoading={isLoading || isFetchingUser}>
                <PersonalInformationsForm
                  formik={formikPersonalInformations}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                />
              </TabLoader>
            ),
            width: '176px',
          },
          {
            label: 'Mot de passe',
            element: (
              <TabLoader isLoading={isLoadingUpdatePassword}>
                <UpdatePasswordForm
                  handleShowPassword={handleShowPassword}
                  showPassword={showPassword}
                  formik={formikUpdatePassword}
                />
              </TabLoader>
            ),
            width: '199px',
          },
        ]}
      />
          
      <CustomModal open={openPopup} handleClose={handleClose} sx={{width: '700px', '& .closeIcon': {display:'none'}}} backdropClick={true}
      >
      <BodyModalWithActions
          title='Votre mot de passe a été mis à jour'
          type='confirm'
          content={
                <Typography variant='body1'>Veuillez-vous reconnecter avec votre nouveau mot de passe.</Typography>
          }
          sx={{
            alignItems: 'center',
            '& .modal-header': {
              flexDirection:'column',
            },
            }}
          actions={
            <>
              <CustomButton variant = 'secondary' onClick={handleLogout}  >
                  Me connecter
              </CustomButton>
            </>
          }
        />
      </CustomModal>
     
    </>
  )
}

export default PersonnalInformations
