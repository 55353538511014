import { useContext, useState } from 'react'
import DocumentTab from './documentTab'
import CustomExternalLink from '../../../../components/common/customExternalLink'
import { useGetDocumentsACQQuery } from '../../../../hooks/api/entreprises'
import { MRT_SortingState } from 'material-react-table'
import { ActiveEnterpriseContext } from '../../../../context/activeEnterpriseContext'

const DocumentsAcq = () => {
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'active_date', desc: true }])
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { data, isFetching } = useGetDocumentsACQQuery(activeEnterprise.crm_id, {
    sort: sorting[0],
  })
  return (
    <DocumentTab
      propsTable={{
        data: data ?? [],
        state: { sorting, isLoading: isFetching },
        onSortingChange: (params) => {
          setSorting(params)
        },
    
      }}
      type='acq'
      text={
        <>
          Dans cet onglet se trouvent les documents mis à votre disposition par votre ACQ régionale.
          Pour consulter ou télécharger les documents réservés aux membres (modèles de documents
          juridiques, grilles des taux horaires au format Excel, etc) ou bien publics (fiches
          techniques, publications, etc.), visitez le{' '}
          <CustomExternalLink href={`${process.env.REACT_APP_ACQ_SITE}/documentation`}>
            Centre de documentation
          </CustomExternalLink>{' '}
          sur acq.org.
        </>
      }
    />
  )
}

export default DocumentsAcq
