import { Box, Stack } from '@mui/material'
import { t } from 'i18next'
import { ContactFormType } from '../../../hooks/pages/entreprise/useEntrepriseProfile'
import EditableTextField from '../../common/fields/editableTextField'
import PhoneComponent from '../../common/phoneComponent'
import OutlinedFieldFormik from '../../formik/fields/outlinedFieldFormik'
import SelectFormik from '../../formik/fields/selectFormik'
import { useGetPostsTitle } from '../../../hooks/api/postsTitle'
import { Option } from '../../../types'
import { getTitle } from '../../../utils'

const listGreetings = [
  { id: 'Mr.', name: 'M.' },
  { id: 'Mme', name: 'Mme' },
]
interface Props {
  values: ContactFormType
  listName?: string
  index?: number
}

const ContactFrom = ({ values }: Props) => {
  const { data: listTitles } = useGetPostsTitle()
  return (
    <Stack spacing='16px'>
      <Box sx={{ display: 'flex' }}>
        <EditableTextField
          sx={{ width: '318px', mr: '24px' }}
          label='Salutations'
          value={ 
            listGreetings.some(item => item.id === values.salutation)
             ? 
            t(`pages.entrepriseProfile.contact.greetings.${values.salutation}`) : '' 
          }
        >
          <SelectFormik
            name='salutation'
            placeholder='Salutations' 
            fullWidth
            listItems={listGreetings}
          />
        </EditableTextField>
        <EditableTextField
          sx={{ width: '318px' }}
          label='Titre'
          value={getTitle(values.title, listTitles)}
        >
          <SelectFormik
            name='title'
            placeholder='Titre'
            fullWidth
            listItems={(listTitles || []) as Option[]}
          />
        </EditableTextField>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <EditableTextField
          label='Prénom'
          required
          sx={{ width: '318px', mr: '24px' }}
          value={values.first_name}
        >
          <OutlinedFieldFormik name='first_name' placeholder='Votre prénom' />
        </EditableTextField>
        <EditableTextField label='Nom' required sx={{ width: '318px' }} value={values.last_name}>
          <OutlinedFieldFormik name='last_name' placeholder='Votre nom  de famille' />
        </EditableTextField>
      </Box>
      <EditableTextField label='Courriel' required sx={{ width: '318px' }} value={values.email}>
        <OutlinedFieldFormik name='email' placeholder='exemple@nomdedomaine.ca' />
      </EditableTextField>
      <PhoneComponent
        object={values.mainPhone}
        typeName='mainPhone.type'
        phoneName='mainPhone.phone_number'
        postName='mainPhone.phone_number_post'
        required
      />
      <PhoneComponent
        object={values.otherPhone}
        typeName='otherPhone.type'
        phoneName='otherPhone.phone_number'
        postName='otherPhone.phone_number_post'
      />
    </Stack>
  )
}

export default ContactFrom
