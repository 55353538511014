import axios, { AxiosResponse } from 'axios'
import i18n from 'i18next'
import { decode } from 'html-entities'; // Import the decoding function

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    'Content-type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
    'Accept-Language': i18n.language ?? 'fr',
  },
})

export const Mock = axios.create({
  baseURL: 'https://bd880442-fd00-43eb-baaf-c2ce41fe9cc2.mock.pstmn.io',
  headers: {
    'Content-type': 'application/json',
    Accept: '*/*',
    'x-api-key': 'PMAK-63e2cff5620bbc53d4ec91c1-5cbe4de1b2dde540471d423c62b0b3048c',
  },
})

const addUtf8Interceptors = (axiosInstance: typeof instance) => {

 //  Response Interceptor (Decode HTML Entities)
 axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && typeof response.data === 'object') {
      //  Decode all string values inside response.data
      const decodeObject = (obj: any): any => {
        if (typeof obj === 'string') {
          return decode(obj); // Decode HTML entities
        } else if (Array.isArray(obj)) {
          return obj.map(decodeObject); // Decode arrays
        } else if (typeof obj === 'object' && obj !== null) {
          return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [key, decodeObject(value)])
          );
        }
        return obj;
      };

      response.data = decodeObject(response.data);
    }
    return response;
  },
  error => Promise.reject(error)
);
};


addUtf8Interceptors(instance);
addUtf8Interceptors(Mock);
export default instance
